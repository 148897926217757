@import (reference) "glyphicons.less";
.glyphicons-fonts();

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #fff;
    background-color: #00ADEE;
    border-color: #0082B3;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: #fff;
        background-color: #37B34A;
        border-color: #2E953E;
    }
}

.secondaryBtn {
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}

.textbox {
    width: 100%;
    font-size: 1em;
    line-height: 1.42857143;
    padding: 6px 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #777;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    background-color: #fff;
    color: #333;
    line-height: 1.42857143;
    margin: 0;

    *, *::before, *::after {
        box-sizing: border-box;
    }
}

form {
    /*border: 1px solid #ddd;
    border-radius: 0.3em;
    padding: 2em 1em;*/
    width: 30em;
    max-width: calc(100vw - 2em);
    margin: 1em auto;
    position: relative;
    /*padding: 3em 1em 2em;

    &::before {
        content: "";
        border: solid #ddd;
        border-width: 0 0 1px;
        border-radius: 0.3em 0.3em 0 0;
        background-color: #f5f5f5;
        height: 1.5em;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }*/
    fieldset {
        border: 0 none;
        margin: 0;
        padding: 0;

        input {
            &[type=text],
            &[type=password] {
                .textbox;
            }
        }
    }
}

h1 {
    margin: 1ch 0;
    font-weight: bold;
    font-size: 1em;
}

input[type=submit],
input[type=button],
button {
    .btn;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a:hover, a:focus {
    color: #23527c;
    text-decoration: underline;
}

small, .small {
    font-size: 85%;
}

footer {
    .small;
    text-align: center;
}

.buttons {
    margin-top: 1em;
}

.text-center {
    text-align: center;
}

#inputWarning {
    color: darkred;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 1em;
    display: block;
    width: 355px;
    white-space: pre-line;
}

#loginSystemMessage {
    color: darkred;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 1em;
    display: block;
    width: 355px;
    white-space: pre-line;
}

#sendTotp {
    button {
        .secondaryBtn;

        i {
            .glyphicons;
            top: 3px;
        }

        &[value=sms] i {
            .glyphicons-iphone;
        }

        &[value=voice] i {
            .glyphicons-earphone;
        }

        &[value=email] i {
            .glyphicons-envelope
        }
    }
}

#totpForm {
    button {
        border-radius: 0;
        margin-right: -1px;
        position: relative;
    }

    button:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    button:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    button:hover {
        z-index: 2;
    }

    input[type=text] {
        width: 8em;
    }
}

.clearClientSession {
    display: none;
}

.idi_logo {
    margin: 50px auto 0;
    margin-bottom: 1em;
    display: block;
    width: 355px;
    max-width: calc(100vw - 2em);
}

.footerlogo {
    height: 66px;
}

.login_container {
    margin-top: 140px;
    margin-bottom: 15px;
}

input.hiddenSubmit {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    outline: none;
    border: 0px;
}

.legalUse {
    width: auto;
    max-width: 50em;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: content-box;
    text-align: center;
}

.legalUse .intro {
    margin: 20px 0;
}

.legalUse .disclaimer {
    .small;
}

.legalUse button {
    .btn;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    white-space: normal;
    line-height: 1.4;
    width: 100%;
}

.ipAddress {
    position: fixed;
    bottom: 20px;
    left: 20px;
}

#connection-info {
    .label;
}

#LoginForm, #SsoForm {
    text-align: center;
}

label {
    display: block;
    text-align: left;
    font-weight: normal;
}

#loginSubmit, #ssoSumbit {
    margin-top: 16px;
}

#contactLink, #contactDetail {
    font-size: larger;
}

#contactDetail {
    display: none;

    a {
        margin: 0 1em;
        position: relative;

        &:hover, &:focus {
            color: #337ab7;
            text-decoration: none;
        }

        ~ a::before {
            content: "|";
            position: absolute;
            left: -1em;
        }
    }
}

.forgotPwd, .sso {
    text-align: center;
    margin-top: 20px;
}

.success {
    color: #5cb85c;
}

.error {
    color: #d9534f;
}

.warning {
    color: #f0ad4e;
}

#resetForUser input[type=submit] {
    margin-top: 10px;
}

#sso-post-form input[type=submit] {
    margin-top: 10px;
}

#changePasswordForm {
    width: 350px;
}

#sso-user {
    margin-bottom: 20px;
}

#sso-back-button {
    margin-top: 20px;
    margin-bottom: 14px;
}

#idp-options {
    display: flex;

    div {
        display: flex;
        margin-right: 15px;
    }
}

.missing-migrations {
    text-align: center;
    width: 100%;
    border: solid #ccc;
    border-width: 1px 0;
    background-color: #eee;

    h1 {
        font-size: 2em;
    }
    h2 {
        margin-bottom: 0;
    }
    ul {
        margin: 0 0 1em;
        padding: 0;
    }
    li {
        display: block;
        padding: 0;
        margin: 0;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        width: 50%;
    }
    th {
        text-align: right;
        padding-right: 4px;
    }
    td {
        text-align: left;
        padding-left: 4px;
    }
}
